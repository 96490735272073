<xpo-card class="component-dialog">
  <xpo-card-title>Component</xpo-card-title>
  <xpo-card-content class="component-dialog-content">
    <form [formGroup]="componentForm" class="component-dialog-form" *ngIf="currentComponent$ | async; else loading">
      <div fxLayout="row" class="component-dialog-form-group" fxLayoutGap="5px" fxLayoutAlign="space-between ">
        <mat-form-field fxFlex="50" floatLabel="always">
          <mat-label class="component-dialog-form-field-required">Name</mat-label>
          <input formControlName="componentName" matInput />
        </mat-form-field>
        <mat-form-field fxFlex="50" floatLabel="always">
          <mat-label class="component-dialog-form-field-required">Code</mat-label>
          <input formControlName="componentCd" matInput />
          <mat-error *ngIf="componentForm.get('componentCd').errors?.maxlength">Must be 3 characters or less</mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row" class="component-dialog-form-group" fxLayoutGap="5px" fxLayoutAlign="space-between ">
        <mat-form-field fxFlex="50" floatLabel="always">
          <mat-label class="component-dialog-form-field-required">Context Url</mat-label>
          <input formControlName="contextUrl" matInput />
        </mat-form-field>
        <mat-form-field fxFlex="50" floatLabel="always">
          <mat-label class="component-dialog-form-field-required">Contract Repo Name</mat-label>
          <input formControlName="contractRepoName" matInput />
        </mat-form-field>
      </div>
      <div fxLayout="row" class="component-dialog-form-group" fxLayoutGap="5px" fxLayoutAlign="space-between ">
        <mat-form-field fxFlex="50" floatLabel="always">
          <mat-label>Architect Name</mat-label>
          <input formControlName="architectName" matInput />
        </mat-form-field>
        <mat-form-field fxFlex="50" floatLabel="always">
          <mat-label class="component-dialog-form-field-required">Jira Component</mat-label>
          <input formControlName="jiraComponent" matInput />
        </mat-form-field>
      </div>
      <div fxLayout="row" class="component-dialog-form-group" fxLayoutGap="5px" fxLayoutAlign="space-between ">
        <mat-form-field fxFlex="50" floatLabel="always">
          <mat-label class="component-dialog-form-field-required">Version</mat-label>
          <input formControlName="versionNbrTxt" matInput />
        </mat-form-field>
        <mat-form-field fxFlex="50" floatLabel="always">
          <mat-label>Owning Team</mat-label>
          <input formControlName="owningTeam" matInput />
        </mat-form-field>
      </div>
      <div fxLayout="row" class="component-dialog-form-group" fxLayoutGap="5px" fxLayoutAlign="space-between ">
        <mat-form-field fxFlex="50" floatLabel="always">
          <mat-label>Owning Team Manager</mat-label>
          <input formControlName="owningTeamManager" matInput />
        </mat-form-field>
        <mat-form-field fxFlex="50" floatLabel="always">
          <mat-label class="component-dialog-form-field-required">Super Component (ComponentCd)</mat-label>
          <input formControlName="superComponent" matInput />
          <mat-error *ngIf="componentForm.get('superComponent').errors?.maxlength">Must be 3 characters or less</mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row" class="component-dialog-form-group">
        <mat-form-field fxFlex="100" floatLabel="always">
          <mat-label>Owning Team Technical Lead</mat-label>
          <input formControlName="owningTeamTechnicalLead" matInput />
        </mat-form-field>
      </div>
      <div fxLayout="row" class="component-dialog-form-group">
        <mat-form-field fxFlex="100" floatLabel="always">
          <mat-label class="component-dialog-form-field-required">Description</mat-label>
          <textarea class="component-dialog-textarea" formControlName="componentDescription" matInput></textarea>
        </mat-form-field>
      </div>
    </form>
    <ng-template #loading>
      <div class="component-dialog-loader">
        <div class="component-dialog-busyContainer">
          <xpo-busy-loader [slow]="true"></xpo-busy-loader>
        </div>
      </div>
    </ng-template>
  </xpo-card-content>
  <xpo-card-footer *ngIf="currentComponent$ | async">
    <div fxLayout="row" class="component-dialog-footer">
      <div fxLayout="row" fxFlex="50" fxLayoutAlign="flex-start center">
        <p class="component-dialog-form-field-required required-label">Fields required</p>
      </div>
      <div fxLayout="row" fxFlex="50" fxLayoutGap="5px" fxLayoutAlign="flex-end center">
        <button mat-flat-button [disabled]="componentForm.invalid" (click)="save()">Save</button>
        <button mat-stroked-button (click)="cancel()">Cancel</button>
      </div>
    </div>
  </xpo-card-footer>
</xpo-card>
